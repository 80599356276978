import Feature from '@/types/feature';

import { COOKIE_AB_FEATURES_OVERRIDE } from '@/constants';
import Cookies from 'js-cookie';
import fetchInternalApi from '../backendApi/utils/fetchInternalApi';
import throwError from '../backendApi/utils/throwError';

function mergeFeatures(features: Feature[]) {
  const abFeaturesRaw = Cookies.get(COOKIE_AB_FEATURES_OVERRIDE);
  const abFeatures = JSON.parse(abFeaturesRaw || '[]') as Feature[];

  return features.map((feature) => {
    const override = abFeatures.find((f) => f.id === feature.id);
    return override || feature;
  });
}

export const getFeatures = async () => {
  const data = await fetchInternalApi<{ features: Feature[] }>(`features`, {});

  if (throwError(data)) return [];

  return mergeFeatures(data.features);
};

export const getFeature = async (id: string): Promise<Feature | undefined> => {
  const features = await getFeatures();
  return features?.find((f: Feature) => f.id === id);
};

export const hasFeature = async (
  id: string,
  value?: string,
): Promise<boolean> => {
  const feature = await getFeature(id);
  return feature ? feature.variant.id === (value || 'ON') : false;
};
